body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

a:link, a:visited {
    color: #0029FF;
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}

#mapId{
    height: 450px;
    width: 100%;
}

.leaflet-div-icon{
    border: 0!important;
    border-radius: 50%;
}
